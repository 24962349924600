/* eslint-disable */
<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
  <template v-if="!loading">
  <div id="pdf_loader">
    <template v-for="(currentStore) in items">
      <div class="with-page-break">
        <h1>{{ currentStore.account_abbrev }}</h1>
        <table cellspacing="0" border="0">
          <colgroup width="98"></colgroup>
          <colgroup span="4" width="72"></colgroup>
          <colgroup span="3" width="80"></colgroup>
          <colgroup width="158"></colgroup>
          <colgroup width="127"></colgroup>
          <tr>
            <td colspan=9 height="16" align="center"><b><font face="Times New Roman" size=1></font></b></td>
            <td align="left"><b><font face="Times New Roman" size=1><br></font></b></td>
          </tr>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" colspan=9 height="17" align="center" bgcolor="#3366FF"><b><font face="Times New Roman" size=1 color="#FFFFFF">{{ currentStore.account_name }}</font></b></td>
            <td align="center"><font face="Times New Roman" size=1><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="15" align="center" bgcolor="#3366FF"><b><font face="Times New Roman" size=1 color="#FFFFFF">DATE</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#3366FF"><b><font face="Times New Roman" size=1 color="#FFFFFF">PHONE</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#3366FF"><b><font face="Times New Roman" size=1 color="#FFFFFF">TRAFFIC</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#3366FF"><b><font face="Times New Roman" size=1 color="#FFFFFF">SOLD</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#3366FF"><b><font face="Times New Roman" size=1 color="#FFFFFF">DELIVERED</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#3366FF" sdnum="1033;0;0%"><b><font face="Times New Roman" size=1 color="#FFFFFF">CLOSING%</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#3366FF" sdnum="1033;0;0%"><b><font face="Times New Roman" size=1 color="#FFFFFF">NEW</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#3366FF" sdnum="1033;0;0%"><b><font face="Times New Roman" size=1 color="#FFFFFF">USED</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#3366FF"><b><font face="Times New Roman" size=1 color="#FFFFFF">GROSS</font></b></td>
            <td align="center"><font face="Times New Roman" size=1><br></font></td>
          </tr>


          <template v-for="(item) in currentStore.daily">
            <tr :key="Math.floor(Math.random() * (100000 - 1 + 1)) + item.activity_date">
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="15" align="center" :bgcolor="getRowColor(item)" sdval="44105" sdnum="1033;1033;D-MMM"><b><font face="Times New Roman" size=1 :color="getFontColor(item)">{{ formatAbbreviatedDate(item.activity_date, false, '-') }}</font></b></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" :bgcolor="getRowColor(item)" sdval="5" sdnum="1033;1033;0"><b><font face="Times New Roman1" size=1 :color="getFontColor(item)">{{ formatNumber(item.phone) }}</font></b></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" :bgcolor="getRowColor(item)" sdval="20" sdnum="1033;1033;0"><b><font face="Times New Roman1" size=1 :color="getFontColor(item)">{{ formatNumber(item.traffic) }}</font></b></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" :bgcolor="getRowColor(item)" sdval="7" sdnum="1033;1033;0"><b><font face="Times New Roman1" size=1 :color="getFontColor(item)">{{ formatNumber(item.sold) }}</font></b></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" :bgcolor="getRowColor(item)" sdval="7" sdnum="1033;1033;0"><b><font face="Times New Roman1" size=1 :color="getFontColor(item)">{{ formatNumber(item.delivered) }}</font></b></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" :bgcolor="getRowColor(item)" sdval="0.35" sdnum="1033;0;0%"><b><font face="Times New Roman" size=1 :color="getFontColor(item)">{{ formatNumber(item.closing_percent) }}%</font></b></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000" align="center" :bgcolor="getRowColor(item)" sdval="4" sdnum="1033;1033;0"><b><font face="Times New Roman1" size=1 :color="getFontColor(item)">{{ formatNumber(item.delivered_new) }}</font></b></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000" align="center" :bgcolor="getRowColor(item)" sdval="3" sdnum="1033;1033;0"><b><font face="Times New Roman1" size=1 :color="getFontColor(item)">{{ formatNumber(item.delivered_used) }}</font></b></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" :bgcolor="getRowColor(item)" sdval="17979" sdnum="1033;0;&quot;$&quot;#,##0.00"><b><font face="Times New Roman1" size=1 :color="getFontColor(item)">{{ formatPrice(item.gross) }}</font></b></td>
              <td align="center" sdnum="1033;0;&quot;$&quot;#,##0.00"><font face="Times New Roman" size=1><br></font></td>
            </tr>
          </template>

          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="15" align="center" bgcolor="#FFFFFF"><b><font face="Times New Roman" size=1>Totals:</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#FFFFFF" sdval="107" sdnum="1033;0;0"><b><font face="Times New Roman" size=1>{{ formatNumber(currentStore.total_phone) }}</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#FFFFFF" sdval="288" sdnum="1033;0;0"><b><font face="Times New Roman" size=1>{{ formatNumber(currentStore.total_traffic) }}</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#FFFFFF" sdval="132" sdnum="1033;0;0"><b><font face="Times New Roman" size=1>{{ formatNumber(currentStore.total_sold) }}</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#FFFFFF" sdval="132" sdnum="1033;0;0"><b><font face="Times New Roman" size=1>{{ formatNumber(currentStore.total_delivered) }}</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#FFFFFF" sdval="0.458333333333333" sdnum="1033;0;0%"><b><font face="Times New Roman" size=1>{{ formatNumber(currentStore.total_closing_percent) }}%</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#FFFFFF" sdval="90" sdnum="1033;0;0"><b><font face="Times New Roman" size=1>{{ formatNumber(currentStore.total_delivered_new) }}</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#FFFFFF" sdval="42" sdnum="1033;0;0"><b><font face="Times New Roman" size=1>{{ formatNumber(currentStore.total_delivered_used) }}</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#FFFFFF" sdval="506037" sdnum="1033;0;&quot;$&quot;#,##0"><b><font face="Times New Roman" size=1>{{ formatPrice(currentStore.total_gross) }}</font></b></td>
            <td style="border-left: 1px solid #000000" align="center"><b><font face="Times New Roman" size=1><br></font></b></td>
          </tr>
          <tr>
            <td height="15" align="center"><b><font face="Times New Roman" size=1><br></font></b></td>
            <td align="center"><b><font face="Times New Roman" size=1>PHONE</font></b></td>
            <td align="center"><b><font face="Times New Roman" size=1>TRAFFIC</font></b></td>
            <td align="center"><b><font face="Times New Roman" size=1>SOLD</font></b></td>
            <td align="center"><b><font face="Times New Roman" size=1>DELIVERED</font></b></td>
            <td align="center" sdnum="1033;0;0%"><b><font face="Times New Roman" size=1>CLOSING%</font></b></td>
            <td align="center" sdnum="1033;0;0%"><b><font face="Times New Roman" size=1>NEW</font></b></td>
            <td align="center" sdnum="1033;0;0%"><b><font face="Times New Roman" size=1>USED</font></b></td>
            <td align="center"><b><font face="Times New Roman" size=1>GROSS</font></b></td>
            <td align="center" sdnum="1033;0;0%"><font face="Times New Roman" size=1><br></font></td>
          </tr>
        </table>
      </div>
    </template>
    <!-- ************************************************************************** -->



  </div>
  </template>
  </v-container>
</template>
<script>
  import DarService from '@/services/DarService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'

  export default {
    name: 'SalesDarStoreSummary',
    components: {
    },
    mixins: [formatterMixin],
    data () {
      return {
        items: [],
        loading: true,
        error: '',
        date: null,
        currentStore: null,
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName']),
    },
    created () {
      this.loadDars()
    },
    methods: {
      loadDars: function () {
        this.loading = true
        this.items = []
        var data = {
          dgid: this.$route.query.dgid,
        }

        DarService.getGroupSummary(data)
          .then(response => {
            this.items = response.data.dars
            console.log(this.items)
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
      rowBgColor(index) {
        if (index%2 == 0)
          return "#C0C0C0"
        else
          return false;
        return "#FFFFFF"
      },
      profitBgColor (profit, index) {
        if (profit === null)
          return this.rowBgColor(index)
        if (profit >= 3000) 
          return "#C7EFCF"
        if (profit >= 2500 && profit < 3000)        
          return "#C6D9F0"
        if (profit >= 2000 && profit < 2500)        
          return "#FAD5B6"
        if (profit < 2000)
          return "#FDC7CE"
      },
      comparisonColor (percentChange) {
        if (percentChange > 1)
          return "#1AAF54"
        if (percentChange >= -9 && percentChange <= 1)
          return "#976513"
        if (percentChange < -9)
          return "#FB0219"
      },
      getFontColor(item) {
        if (item.saturday)
          return '#FFFFFF'
        else 
          return '#000000'
      },
      getRowColor(item) {
        if (item.saturday)
          return '#000000'
        else 
          return '#FFFFFF'
      },
    },
  }
</script>
<style>
  .with-page-break {
    page-break-before: always;
    page-break-after: always;
  }
</style>